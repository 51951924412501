// JavaScript File
import React, {Component} from 'react';



class Privacy extends Component {
render() {
  return (
<section className="relative pb-2">
<div className='max-w-[1240px] mx-auto text-black relative'>

<div>

<br></br>
<br></br>
<br></br>
<br></br>

<center><h3>PRIVACY POLICY</h3> 
Last updated August 18, 2024</center>




<p>
<br></br>
This privacy notice for	("we," "us," or "our), describes how and why we might collect, store, use, and/or share ("process") your information when you use our services
("Services"), such as when you:
<br></br>
•	Download and use our mobile application (Megax_Thermal_Printer), or any other application of ours that links to this privacy notice
<br></br>
•	Engage with us in other related ways, including any sales, marketing, or events
Questions or concerns? Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at <b>jaber.al-ani@outlook.com</b>.

<br></br>
<br></br>

<h3>SUMMARY OF KEY POINTS</h3>
This summary provides key points from our privacy notice.
<br></br>
<br></br>
•	What personal information do we process? When you visit, use, or navigate our Services, we do NOT process personal information.
<br></br>
<br></br>
•	Do we process any sensitive personal information? We do not process sensitive personal information. 
<br></br>
<br></br>
•	Do we collect any information from third parties? We do not collect any information from third parties.
<br></br>
<br></br>
• In what situations and with which parties do we share personal information? We do NOT share information with third parties.

<br></br>
<br></br>

<h3>WHAT INFORMATION DO WE COLLECT? </h3>
We collect personal information that you voluntarily provide to us when you express an interest in obtaining information about us or our products and Services i.e when you contact us.

<br></br>
<br></br>
<h3>HOW LONG DO WE KEEP YOUR INFORMATION? </h3>
We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.
We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements).


<br></br>
<br></br>
<h3>DO WE COLLECT INFORMATION FROM MINORS?</h3>
In Short: We do not knowingly collect data from or market to children under 18 years of age.
We do not knowingly collect, solicit data from, or market to children under 18 years of age, nor do we knowingly sell such personal information. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent's use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at jaber.al-ani@outlook.com.

<br></br>
<br></br>
<h3>WHAT ARE YOUR PRIVACY RIGHTS?</h3>
In Short: You may review, change, or terminate your account at any time, depending on your country, province, or state of residence.
Withdrawing your consent: If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below.
However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows. will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.
 
If you have questions or comments about your privacy rights. you may email us at <b>jaber.al-ani@outlook.com</b>.


<br></br>
<br></br>
<h3>CONTROLS FOR DO-NOT-TRACK FEATURES</h3>
Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage, no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.

<br></br>
<br></br>
<h3>DO WE MAKE UPDATES TO THIS NOTICE?</h3>
In Short: Yes, we will update this notice as necessary to stay compliant with relevant laws.
We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date at the top of this privacy notice. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification.

<br></br>
<br></br>
<h3>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h3>
If you have questions or comments about this notice, you may email us at <b>jaber.al-ani@outlook.com</b>  or contact us by post at:
<br></br>
Hirschenau 50
<br></br>
Sankt Nikola an der Donau,
<br></br>
 Upper Austria
<br></br>
 4382 Austria
<br></br>


<br></br>
<h3>HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</h3>
Based on the applicable laws of your country you may have the right to request access to the personal information we collect from you, details about how we have processed it, correct inaccuracies, or delete your personal information. You may also have the right to withdraw your consent to our processing of your personal information. These rights may be limited in some circumstances by applicable law. To request to review, update, or delete your personal information, please reach <b>jaber.al-ani@outlook.com</b>.




</p>




</div>
</div>
</section>
  );
}  
}

export default Privacy;

