/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Navigation from "./Pages/Navigation";
import Home from './Pages/Home';
import Footer from './Pages/Footer';
import Projects from './Pages/Projects';
import Contact from './Pages/Contact';
import Privacy from './Pages/Privacy';


import 'bootstrap/dist/css/bootstrap.css';


import {BrowserRouter as Router, Routes, Route } from 'react-router-dom';


function App () {
    return (
    <div>
    <Navigation />

    
    <Routes>
      
      <Route path="/">
        <Route index element={<Home />} />
        <Route path="home" element={<Home />} />
        <Route path="Projects" element={<Projects/>} />
        <Route path="Privacy" element={<Privacy/>} />
        <Route path="Contact" element={<Contact/>} />
        

        

      </Route>
    </Routes>
    
   
    <Footer />
  </div>
  
  );
}  


export default App;
