/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState,} from 'react'

import { Link } from "react-router-dom";
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { FaUserAlt, FaSignInAlt } from 'react-icons/fa';


import { Navigate } from "react-router-dom";

function Navigation() {
    const [nav, setNav] = useState(false)
    
    const handleClick = () => setNav(!nav)

    const handleClose = () => {
      setNav(false);
    };


    return (
      // Navigation bar on large screen
      <div className="w-screen bg-gray-100 h-[70px] z-10 fixed drop-shadow-lg">
        <div className="px-2 flex justify-between items-center w-full h-full">
          <div className="flex items-center">
            <h1 className="text-3xl font-bold text-black mr-4 sm:text-4xl">
              Megax
            </h1>
            <ul className="hidden text-black md:flex">
              <li className="cursor-pointer hover:bg-white hover:text-green-600 hover:rounded-lg">
                <Link to="/home">Home</Link>
              </li>
  
              <li className="cursor-pointer relative group hover:bg-white hover:text-green-600 hover:rounded-lg">
                <Link to="/Projects">Projects</Link>
              </li>
  
              <li className="cursor-pointer relative group hover:bg-white hover:text-green-600 hover:rounded-lg">
                <Link to="/Privacy">Privacy</Link>
              </li>
  
              <li className="cursor-pointer relative group hover:bg-white hover:text-green-600 hover:rounded-lg">
                <Link to="/Contact">Contact</Link>
              </li>
            </ul>
          </div>
          
  
          <div className="md:hidden mr-4" onClick={handleClick}>
            {!nav ? (
              <MenuIcon className="w-5 text-black" />
            ) : (
              <div className="flex">
                
  
               
                <XIcon className="w-5 text-black" />
              </div>
            )}
          </div>
        </div>
  
        {/* Navigation on small screens */}
        <ul className={!nav ? "hidden" : "absolute bg-zinc-200 w-full px-8"}>
          <li onClick={handleClose} className="border-b-2 border-zinc-300 w-full">
          <Link to="/home">Home</Link>
          </li>
  
          <li onClick={handleClose} className="border-b-2 border-zinc-300 w-full">
          <Link to="/Projects">Projects</Link>
          </li>
  
          <li onClick={handleClose} className="border-b-2 border-zinc-300 w-full">
          <Link to="/Privacy">Privacy</Link>
          </li>
  
          <li onClick={handleClose} className="border-b-2 border-zinc-300 w-full">
          <Link to="/Contact">Contact</Link>
          </li>
        </ul>
      </div>
    );
  }
  
  export default Navigation;