// JavaScript File
import React, {Component} from 'react';

import {Link} from 'react-router-dom';




const navStyle = {
   color: 'black'
};

class Footer extends Component {
render() {
  return (

    


    
    <footer className="bg-gray-50">
    <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center sm:justify-center">
       
       <p className="mt-4 text-center text-sm text-gray-500 lg:mt-0 lg:text-center">
       Copyright &copy; 2024. All rights reserved.
       </p>

       
       
      </div>
    </div>
  </footer>
  
  


  );
}  
}

export default Footer;