// JavaScript File
import React, {Component} from 'react';
import Table from 'react-bootstrap/Table';
import 'bootstrap/dist/css/bootstrap.css';

class Projects extends Component {
render() {
  return (
  
<section className="relative pb-2"> 
<div className='max-w-[1240px] mx-auto text-white relative'>   
  <center>
  <div className='px-4 py-12 md:block hidden'>
  <div style={{ display: 'block', width: 800, padding: 30 }}>

<div>  </div>
<br></br>
<br></br>
<br></br>

<h2>currently working on</h2>

<Table striped bordered hover>
      <thead>
        <tr>
          <th>#</th>
          <th>github</th>
          <th>Demo</th>
          <th>Technology</th>
          <th>Published</th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td>1</td>
          <td><a href="https://github.com/powermator/Megax_Thermal_Printer">Megax_Thermal_Printer</a></td>
          <td> <img src="https://megax.s3.amazonaws.com/soon.png"/> </td>
          <td><img src="https://megax.s3.amazonaws.com/flutter.png" alt="Flutter"/>Flutter <img src="https://megax.s3.amazonaws.com/HTML.png" alt="Flutter"/> <img src="https://megax.s3.amazonaws.com/CSS.png" alt="Flutter"/> </td>
          <td> <a href="https://play.google.com/apps/internaltest/4700674980179119152">Playstore</a>  </td>
        </tr>
        <tr>
          <td>2</td>
          <td> <a href="https://github.com/powermator/Megax_Sale_Point">Megax_Sale_Point</a></td>
          <td> <img src="https://megax.s3.amazonaws.com/soon.png"/> </td>
          <td> <img src="https://megax.s3.amazonaws.com/sql.png"/> <img src="https://megax.s3.amazonaws.com/MAUI.png"/> </td>
          <td> -- </td>
        </tr>
        <tr>
          <td>3</td>
          <td> <a href="https://github.com/powermator/React-My-App">Megax Website</a></td>
          <td>megax.at</td>
          <td><img src="https://megax.s3.amazonaws.com/react.png"/> <img src="https://megax.s3.amazonaws.com/aws.png"/>  </td>
          <td>megax.at</td>
        </tr>
      </tbody>
    </Table>

   <br></br>
   <br></br>

   

   <br></br>
   <br></br>
   <br></br>
   <br></br>
  
  
   <h2>old work</h2>
   <Table striped bordered hover>
      <thead>
        <tr>
          <th>#</th>
          <th>github</th>
          <th>Demo</th>
          <th>Technology</th>
          <th>Published</th>
        </tr>
      </thead>

      <tbody>
      <tr>
          <td>1</td>
          <td><a href="https://github.com/powermator/Lazy-Admin/tree/main?tab=readme-ov-file"> Lazy Admin</a> </td>
          <td><a href="https://raw.githubusercontent.com/powermator/Lazy-Admin/main/Lazy.png">screenshot</a></td>
          <td>Powershell, PowerShell Studio </td>
          <td>used by a customer (2018) </td>
        </tr>

      <tr>
          <td>2</td>
          <td> CRM (Front & Back end) </td>
          <td>  </td>
          <td><img src="https://megax.s3.amazonaws.com/asp.png"/> <img src="https://megax.s3.amazonaws.com/vbnet.png"/> <img src="https://megax.s3.amazonaws.com/MSsql.png"/> <img src="https://megax.s3.amazonaws.com/cr.png"/> </td>
          <td>used by a customer (2010 - 2013) </td>
        </tr>

        <tr>
          <td>3</td>
          <td><a href="https://github.com/powermator/Game-Demo-VB6">3D Game Demo</a></td>
          <td><a href="https://raw.githubusercontent.com/powermator/Game-Demo-VB6/main/PIC2006828133410272.JPG">screenshot</a></td>
          <td><img src="https://megax.s3.amazonaws.com/vb6.jpg"/> <img src="https://megax.s3.amazonaws.com/dx.png"/></td>
          <td>Plantesourcecode on 2006-08-24 16:45:06</td>
        </tr>
        <tr>
          <td>4</td>
          <td><a href="https://github.com/powermator/Game-GUI-VB6-DX8">Game GUI</a></td>
          <td><a href="https://raw.githubusercontent.com/powermator/Game-GUI-VB6-DX8/main/PIC2006829651465297.JPG">screenshot</a></td>
          <td><img src="https://megax.s3.amazonaws.com/vb6.jpg"/> <img src="https://megax.s3.amazonaws.com/dx.png"/></td>
          <td>Plantesourcecode on 2006-08-25 13:37:54</td>
        </tr>
       
      </tbody>
    </Table>

   <br></br>
   
   <br></br>
  

  
   </div>
   </div>
  </center>
  </div>
  </section>

  );
}  
}

export default Projects;

