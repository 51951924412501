// JavaScript File
import React, {Component} from 'react';



class contact extends Component {
render() {
  return (
<section className="relative pb-2">
<div className='max-w-[1240px] mx-auto text-black relative'>      
<center>

<div>  </div>
<br></br>
   <br></br>
   <br></br>
   <br></br>
  
  
   

  
   
  
  
  </center>
  </div>
  </section>
  );
}  
}

export default contact;

